<template>
  <div class="orderDetail-container">
    <el-drawer class="orderDetail-drawer" title="新签订单详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="handleClose" >
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
          <el-form :model="editRuleForm" :rules="editRules" ref="editRuleForm" class="demo-ruleForm" >
            <div class="detail-title">
              <div class="tit-info hoverEdit" @click="() => {editBtn('name', OrderInfo.name)}" v-if="editParams.filedName != 'name'">
                {{ (OrderInfo && OrderInfo.name) || "--" }}
              </div>
              <el-form-item v-if="editParams.filedName == 'name'" class="edit-input-container" prop="name">
                <el-input class="edit-input" placeholder="请输入名称" v-model="editRuleForm.name" @blur="() => {editOrderDetail('name')}">
                  <template slot="append">
                    <div @click="() => {editCancelBtn()}" class="cancel">取消</div>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <div class="detail-content">
              <div class="DisableEdit">
                客户名称:
                <span class="common-clr-0a6">{{(OrderInfo && OrderInfo.customerName) || "--"}}</span>
              </div>
              <div class="DisableEdit" v-if="editParams.filedName == 'price'">
                订单金额:
                <span class="modifyPrice" @click="()=>{editShow()}">
                  {{(OrderInfo && OrderInfo.price) || "--"}}
                </span>
              </div>
              <div @click="() => {editBtn('price', OrderInfo.price)}" v-if="editParams.filedName != 'price'" class="hoverEdit">
                订单金额:
                <span class="common-clr-0a6">
                  {{ (OrderInfo && OrderInfo.price) || "--" }}
                </span>
              </div>
              <el-form-item v-if="editParams.filedName == 'startTime'" class="edit-input-container" prop="startTime" label="开始时间:">
                <el-date-picker class="edit-input" v-model="editRuleForm.startTime" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" @change="(e) => {editOrderDetail('startTime')}" placeholder="选择日期"></el-date-picker>
              </el-form-item>
              <div @click="() => {editBtn('startTime', DateTransform(OrderInfo.startTime))}" v-if="editParams.filedName != 'startTime'" class="hoverEdit">
                开始时间:
                <span class="common-clr-0a6">
                  {{ (OrderInfo && DateTransform(OrderInfo.startTime)) || "--" }}
                </span>
              </div>
              <el-form-item v-if="editParams.filedName == 'endTime'" class="edit-input-container" prop="endTime" label="结束时间:">
                <el-date-picker class="edit-input" v-model="editRuleForm.endTime" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" @change="(e) => {editOrderDetail('endTime')}" placeholder="选择日期"></el-date-picker>
              </el-form-item>
              <div @click="() => {editBtn('endTime', DateTransform(OrderInfo.endTime))}" v-if="editParams.filedName != 'endTime'" class="hoverEdit">
                结束时间:
                <span class="common-clr-0a6">
                  {{ (OrderInfo && DateTransform(OrderInfo.endTime)) || "--" }}
                </span>
              </div>
              <el-form-item v-if="editParams.filedName == 'note'" class="edit-input-container" prop="note" label="订单备注:">
                <el-input class="edit-input" placeholder="请输入内容" v-model="editRuleForm.note" @blur="() => {editOrderDetail('note')}">
                  <template slot="append">
                    <div @click="() => {editCancelBtn()}" class="cancel">取消</div>
                  </template>
                </el-input>
              </el-form-item>
              <div @click="() => {editBtn('note', OrderInfo.note)}" v-if="editParams.filedName != 'note'" class="hoverEdit">
                订单备注:
                <span class="common-clr-0a6">
                  {{(OrderInfo && OrderInfo.note) || "--"}}
                </span>
              </div>
              <el-form-item v-if="editParams.filedName == 'agreeShopNum'" class="edit-input-container" prop="agreeShopNum" label="协议店铺数:">
                <el-input class="edit-input" placeholder="请输入内容" v-model="editRuleForm.agreeShopNum" type="number" @blur="() => {editOrderDetail('agreeShopNum')}">
                  <template slot="append">
                    <div @click="() => {editCancelBtn()}" class="cancel">取消</div>
                  </template>
                </el-input>
              </el-form-item>
              <div @click="() => {editBtn('agreeShopNum', OrderInfo.agreeShopNum)}" v-if="editParams.filedName != 'agreeShopNum'" class="hoverEdit">
                协议店铺数:
                <span class="common-clr-0a6">
                  {{(OrderInfo && OrderInfo.agreeShopNum) || "--"}}
                </span>
              </div>
              <el-form-item v-if="editParams.filedName == 'agreeSubNum'" class="edit-input-container" prop="agreeSubNum" label="协议子账号数:">
                <el-input class="edit-input" placeholder="请输入内容" v-model="editRuleForm.agreeSubNum" type="number" @blur="() => {editOrderDetail('agreeSubNum')}">
                  <template slot="append">
                    <div @click="() => {editCancelBtn()}" class="cancel">取消</div>
                  </template>
                </el-input>
              </el-form-item>
              <div @click="() => {editBtn('agreeSubNum', OrderInfo.agreeSubNum)}" v-if="editParams.filedName != 'agreeSubNum'" class="hoverEdit">
                协议子账号数:
                <span class="common-clr-0a6">
                  {{(OrderInfo && OrderInfo.agreeSubNum) || "--"}}
                </span>
              </div>
              <el-form-item v-if="editParams.filedName == 'conslutDayNum'" class="edit-input-container editInputContainer" prop="conslutDayNum" label="协议单账号日均咨询量:">
                <el-input class="edit-input" placeholder="请输入内容" v-model="editRuleForm.conslutDayNum" type="number" @blur="() => {editOrderDetail('conslutDayNum')}">
                  <template slot="append">
                    <div @click="() => {editCancelBtn()}" class="cancel">取消</div>
                  </template>
                </el-input>
              </el-form-item>
              <div @click="() => {editBtn('conslutDayNum', OrderInfo.conslutDayNum)}" v-if="editParams.filedName != 'conslutDayNum'" class="hoverEdit">
                协议单账号日均咨询量:
                <span class="common-clr-0a6">
                  {{(OrderInfo && OrderInfo.conslutDayNum) || "--"}}
                </span>
              </div>
            </div>
          </el-form>
          <div>
            <div class="data-content">
              <div class="data-title">系统信息</div>
              <div class="detail-content">
                <div class="DisableEdit">负责人: <span class="common-clr-0a6">{{(adminInfo && adminInfo.nickName) || "--"}}</span></div>
                <div class="DisableEdit">负责人所属部门: <span class="common-clr-0a6">{{(adminInfo && adminInfo.userDepartName) || "--"}}</span></div>
                <div class="DisableEdit">创建人: <span class="common-clr-0a6">{{(adminInfo && adminInfo.nickName) || "--"}}</span></div>
                <div class="DisableEdit">创建时间: <span class="common-clr-0a6">{{(OrderInfo && OrderInfo.createTime) || "--"}}</span></div>
                <div class="DisableEdit">最后修改人: <span class="common-clr-0a6">{{(OrderInfo && OrderInfo.createName) || "--"}}</span></div>
                <div class="DisableEdit">最后修改时间: <span class="common-clr-0a6">{{(OrderInfo && OrderInfo.updateTime) || "--"}}</span></div>
              </div>
            </div>
          </div>
          <div class="upload-container">
            <div class="upload-top">
              <div class="upload-title">
                订单合同
              </div>
              <div class="tip">
                上传图片及文件，大小不超过2MB
                <div class="uploadBtn-container">
                  上传附件
                  <input @input="(e) => { uploadFile(e) }" class="uploadBtn-input" type="file">
                </div>
              </div>
            </div>
            <div v-if="fileList && fileList.length" class="fileList-container">
              <div v-for="(item, index) in fileList" :key="index" class="fileItem">
                <div class="fileItem-name">{{item.name}}</div>
                <div class="fileItem-btn-Container">
                  <div class="fileItemBtn" @click="() => {download(item.url)}">查看</div>
                  <div class="fileItemBtn" @click="() => {download(item.url)}">下载</div>
                  <div class="fileItemBtn" @click="() => {deleteFile(item)}">删除</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="drawer-right">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="工作记录" name="about">
              <div v-if="!followRecordList || !followRecordList.length">
                暂无记录
              </div>
              <div class="record-container">
                <div v-for="(item, index) in followRecordList" :key="index" class="record-item">
                  <div class="item-top">
                    <div class="item-icon"></div>
                    <span class="item-role"> {{ item.createName }} </span>
                    <span class="item-date"> {{ item.createTime }} </span>
                  </div>
                  <div class="item-content">
                    <div class="item-detail" v-if="(item.type || item.type == 0) && item.followType == 1">
                      电话跟进了客户
                    </div>
                    <div class="item-detail" v-if="(item.type || item.type == 0) && item.followType == 2">
                      网络跟进了客户
                    </div>
                    <div class="item-detail" v-if="(item.type || item.type == 0) && item.followType == 3">
                      上门跟进了客户
                    </div>
                    <div class="item-detail" style="color: #333" v-if="item.type || item.type == 0">
                      {{ item.followContent }}
                    </div>
                    <div v-if="item.fileUrl && (typeof item.fileUrl != 'string')">
                      <div v-for="(itemFile, indexFile) in item.fileUrl" :key="indexFile">
                        <img v-if="item.fileType && item.fileType[indexFile] == 'img'" class="fileImg" :src="itemFile" alt=""> 
                        <div v-if="item.fileType && item.fileType[indexFile] != 'img'" class="fileNames">
                          <img style="margin-right: 8px" src="../../../assets/images/file_icon.svg" />
                          {{ item.fileNames&&item.fileNames[indexFile] }}
                        </div>
                      </div>
                    </div>
                    <div class="item-detail" v-if="!item.type && item.type != 0">
                      更改资料
                    </div>
                    <div class="item-detail" style="color: #333" v-if="!item.type && item.type != 0">
                      原本信息：{{ item.originalName }}
                    </div>
                    <div class="item-detail" style="color: #333" v-if="!item.type && item.type != 0">
                      修改后信息：{{ item.infoName }}
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="续签订单" :append-to-body="true" :visible.sync="dialogVisible" width="520px">
      <div>
        <el-form :model="ruleFormAdd" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
          <el-form-item label="订单名称" prop="name">
            <el-input class="dialog-input" v-model="ruleFormAdd.name" placeholder="订单名称" maxLength="50"></el-input>
          </el-form-item>
          <el-form-item label="客户名称" prop="custName">
            <el-input class="dialog-input" :value="ruleFormAdd.custName" placeholder="客户名称" disabled></el-input>
          </el-form-item>
          <el-form-item label="运营指派" prop="toId">
            <el-select class="dialog-input" v-model="ruleFormAdd.toId" placeholder="请选择指派人" filterable>
              <el-option v-for="item in assignPeople" :key="item.id" :label="item.nickName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="通知人" prop="noticeId">
            <el-select class="dialog-input" v-model="ruleFormAdd.noticeId" placeholder="请选择通知人" filterable >
              <el-option v-for="item in userList" :key="item.id" :label="item.nickName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="销售机会金额" prop="price">
            <el-input class="common-input-YUAN" type="number" v-model="ruleFormAdd.price" placeholder="销售机会金额" maxLength="50"></el-input>
          </el-form-item>
          <el-form-item label="订单开始时间" prop="startTime">
            <el-date-picker class="dialog-input" v-model="ruleFormAdd.startTime" type="date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="选择开始时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="订单结束时间" prop="endTime">
            <el-date-picker
              class="dialog-input"
              v-model="ruleFormAdd.endTime"
              type="date"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择结束时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              class="dialog-input"
              type="textarea"
              v-model="ruleFormAdd.note"
              maxLength="500"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="checked">是否有介绍人</el-checkbox>
          </el-form-item>
          <div class="introducer" v-if="checked">
            <el-form-item label="介绍人名称">
              <el-input
                class="dialog-input"
                v-model="ruleFormAdd.introducerName"
                placeholder="介绍人名称"
                maxLength="50"
              ></el-input>
            </el-form-item>
            <el-form-item label="介绍人联系方式">
              <el-input
                class="dialog-input"
                v-model="ruleFormAdd.introducerPhone"
                placeholder="介绍人联系方式"
                maxLength="50"
              ></el-input>
            </el-form-item>
            <el-form-item label="介绍费">
              <el-input
                class="common-input-YUAN"
                type="number"
                v-model="ruleFormAdd.introducerPrice"
                placeholder="介绍费"
                maxLength="50"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => subNewOrderForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="手动调价"
      :append-to-body="true"
      :visible.sync="editVisible"
      width="520px"
    >
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中...">
        <el-form
          :model="ruleFormEdit"
          :rules="ruleEditRules"
          ref="ruleFormEdit"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="原价" prop="originalMoneny">
            <el-input
              class="dialog-input"
              v-model="ruleFormEdit.originalMoneny"
              placeholder="原价"
              maxLength="50"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="改价类型" prop="type">
            <el-radio-group v-model="ruleFormEdit.type" @change="(e) => {changeType(e)}">
              <el-radio :label="0">加价</el-radio>
              <el-radio :label="1">减价</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="调整金额" prop="changeMoneny">
            <el-input
              class="dialog-input"
              v-model="ruleFormEdit.changeMoneny"
              type="number"
              maxLength="50"
              @input="(e) => {changePrice(e)}"
            ></el-input>
          </el-form-item>
          <el-form-item label="改价结果" prop="result">
            <el-input
              type="number"
              class="dialog-input"
              v-model="ruleFormEdit.result"
              disabled
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="通知人" prop="noticeId">
            <el-select
            v-if="noticeList && noticeList.length"
              class="dialog-input"
              v-model="ruleFormEdit.noticeId"
              placeholder="请选择通知人"
              multiple
            >
              <el-option
                v-for="item in noticeList"
                :key="item.id"
                :label="item.nickName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="改价说明">
            <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="ruleFormEdit.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="editVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => {orderPriceEdit('ruleFormEdit')}"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  deleteSea,
  addChanceFollow,
  editOrderDetail,
  updateOrder,
  orderFeedback,
  orderNext,
  saleAndRecharge,
  orderNoticeList,
  orderPriceEdit,
  uploadOrderFile,
  deleteOrderFile
} from "../../../service/customer.js";
import { upload } from "../../../service/upload.js";
import { tableColumn, DateTransform } from "../../../utils/index.js";
export default {
  props: [ "drawer", "tableRow", "followRecordList", "adminInfo", "OrderInfo", "assignPeople", "userList", "loading", "fileList" ],
  data() {
    return {
      noticeList: [], // 通知人下拉
      editRuleForm: {
        startTime: "",
        endTime: "",
        note: "",
        agreeShopNum: "",
        agreeSubNum: "",
        conslutDayNum: "",
        price: "",
        name: ""
      },
      editParams: {
        filedName: "",
        filedValue: "",
      },
      tableColumn,
      DateTransform,
      checked: false, // 是否有介绍人
      ruleFormAdd: {
        // 新增店铺数据
      }, // form提交数据
      rules: {
        // 校验规则
        name: [{ required: true, message: "请填写订单名称", trigger: "change" }],
        custName: [{ required: true, trigger: "change" }],
        toId: [{ required: true, message: "请选择指派人", trigger: "change" }],
        noticeId: [{ required: true, message: "请选择通知人", trigger: "change" }],
        price: [{ required: true, message: "请填写金额", trigger: "change" }],
        startTime: [{ required: true, message: "请选择开始时间", trigger: "change" }],
        endTime: [{ required: true, message: "请选择结束时间", trigger: "change" }],
      },
      ruleFormEdit: {
        // 编辑店铺
        result: ''
      },
      ruleEditRules: {
        // 校验规则
        originalMoneny: [{ required: true, message: "请填写开始时间", trigger: "change" }],
        type: [{ required: true, message: "请选择", trigger: "change" }],
        noticeId: [{ required: true, message: "请选择", trigger: "change" }],
        changeMoneny: [{ required: true, message: "请输入金额", trigger: "change" }],
        result: [{ required: true, message: "请输入金额", trigger: "change" }],
      },
      editRules: {
        // 校验规则
        startTime: [{ required: true, message: "请填写开始时间", trigger: "change" }],
        endTime: [{ required: true, message: "请填写结束时间", trigger: "change" }],
        name: [{ required: true, message: "订单名称不能为空", trigger: "change" }],
      },
      editVisible: false,
      pictureName: "",
      pictureNameText: "",
      fileName: "",
      fileNameText: "",
      fileType: "",
      fileId: "",
      pictureId: "",
      activeName: "about", // tabs
      radio: "1",
      recordRadio: 1, // 跟进记录radio
      recordContent: "", // 跟进记录内容
      nowStage: 13, // 当前阶段
      feedbackContent: "", // 反馈内容
      showFeedback: false,
      dialogVisible: false,
      nextPlan: {
        // 跟进计划
        state: 1,
        type: 1,
        remind: null,
        planTime: "",
        followTx: false,
      }, // 下次跟进计划提交数据
      ServiceFollow: {
        // 阶段任务
        checked0: false,
        checked1: true,
        checked2: true,
      },
      checkPlan: false, // 下次计划按钮
      pickerOptions: {
        // 时间禁用时间范围
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime();
        },
      },
      options: [
        // 筛选下拉
        {
          value: 5,
          label: "5分钟",
        },
        {
          value: 15,
          label: "15分钟",
        },
        {
          value: 30,
          label: "30分钟",
        },
        {
          value: 60,
          label: "一小时",
        },
      ],
    };
  },
  created() {
    this.orderNoticeList()
  },
  methods: {
    download(url) {
      // 下载
      window.location.href = url
    },
    async deleteFile(item) {
      this.$emit("loadFun", true);
      let params = {
        orderId: this.tableRow.id,
        filedId: item.id,
        filedUrl: item.url,
        fileName: item.name
      }
      await deleteOrderFile(params)
      this.$message.success('删除成功')
      this.$emit("getOrderDetail", this.tableRow);
    },
    async uploadFile(e) {
      // 上传合同
      this.$emit("loadFun", true);
      let file = e.target.files[0];
      let fileName = file.name;
      let resData = (await upload({ file: file })).data;
      await uploadOrderFile({orderId: this.tableRow.id, filedId: resData.id, fileName: fileName, filedUrl: resData.filename})
      this.$emit("getOrderDetail", this.tableRow);
    },
    orderPriceEdit (formName) {
      let ruleFormEdit = {...this.ruleFormEdit}
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$emit("loadFun", true);
          if (ruleFormEdit && ruleFormEdit.noticeId) {
            ruleFormEdit.noticeId = ruleFormEdit.noticeId.join(',')
          }
          await orderPriceEdit(ruleFormEdit)
          this.editVisible = false
          this.$emit("getOrderDetail", this.tableRow);
          this.$message.success("调价成功")
        } else {
          return false;
        }
      });
    },
    changeType (e) {
      if (this.ruleFormEdit.changeMoneny) {
        console.log(e)
        if(e == 0) {
          // this.ruleFormEdit.result = Number(this.ruleFormEdit.changeMoneny) + this.ruleFormEdit.originalMoneny
          this.ruleFormEdit= Object.assign({}, this.ruleFormEdit, { result: (Number(this.ruleFormEdit.changeMoneny) + this.ruleFormEdit.originalMoneny)})
        }
        if(e == 1) {
          // this.ruleFormEdit.result = this.ruleFormEdit.originalMoneny - Number(this.ruleFormEdit.changeMoneny)
           this.ruleFormEdit= Object.assign({}, this.ruleFormEdit, { result: (this.ruleFormEdit.originalMoneny - Number(this.ruleFormEdit.changeMoneny))})
        }
      }
    },
    changePrice (e) {
      if(this.ruleFormEdit.type == 0) {
        this.ruleFormEdit.result = Number(e) + this.ruleFormEdit.originalMoneny
      }
      if(this.ruleFormEdit.type == 1) {
        this.ruleFormEdit.result = this.ruleFormEdit.originalMoneny - Number(e)
      }
    },
    async orderNoticeList () {
      let resData = (await orderNoticeList()).data
      this.noticeList = resData
    },
    editCancelBtn() {
      // 取消编辑
      this.editParams = {
        filedName: "",
        filedValue: "",
      };
    },
    editOrderDetail(name) {
      // 光标移出-提交修改
      console.log(this.editRuleForm[name], '-------------------')
      this.$emit("loadFun", true);
      let params = {
        orderId: this.tableRow.id,
        customerId: this.tableRow.customer_id,
        filedName: name,
        filedValue: this.editRuleForm[name],
      };
      setTimeout(() => {
        if (this.editParams.filedName) {
          this.$refs["editRuleForm"].validateField(name, async (error) => {
            if (!error) {
              await editOrderDetail(params);
              this.$emit("getOrderDetail", this.tableRow);
              this.editParams = {
                filedName: "",
                filedValue: "",
              };
            } else {
              this.$emit("loadFun", false);
              return false;
            }
          });
        }
      }, 100);
    },
    editShow() {
      // 显示手动调价
      let ruleFormEdit = {
        orderId: this.tableRow.id,
        filedName: 'price',
        originalMoneny: this.OrderInfo.price,
        type: 0,
        noticeId: this.noticeList && this.noticeList[0] ? [this.noticeList[0].id] : []
      };
      this.ruleFormEdit = ruleFormEdit;
      this.editParams = {
        filedName: "",
        filedValue: "",
      };
      this.editVisible = true;
    },
    async chooseFile(e, type) {
      // 反馈上传文件
      // console.log(type);
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (type == "img") {
        if (
          format != "bmp" &&
          format != "jpg" &&
          format != "png" &&
          format != "gif" &&
          format != "jpeg"
        ) {
          this.$message.error("仅支持bmp、png、jpg、jpeg、gif格式文件");
          e.target.value = "";
          return;
        }
      }
      if (type == "xlsx") {
        if (format != "doc" && format != "docx" && format != "xls" && format != "xlsx") {
          this.$message.error("仅支持doc、docx、xls、xlsx格式文件");
          e.target.value = "";
          return;
        }
      }
      let resData = (await upload({ file: file })).data;

      if (type == "img") {
        this.pictureName = resData.filename;
        this.pictureNameText = file.name;
        this.pictureId = resData.id;
      } else {
        this.fileName = resData.filename;
        this.fileNameText = file.name;
        this.fileId = resData.id;
      }
      this.fileType = type;
    },
    subNewOrderForm(formName) {
      // 销售续费
      let ruleFormAdd = { ...this.ruleFormAdd };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (ruleFormAdd.custName) {
            delete ruleFormAdd.custName;
          }
          if (!this.checked) {
            if (ruleFormAdd.introducerName) {
              delete ruleFormAdd.introducerName;
            }
            if (ruleFormAdd.introducerPhone) {
              delete ruleFormAdd.introducerPhone;
            }
            if (ruleFormAdd.introducerPrice) {
              delete ruleFormAdd.introducerPrice;
            }
          }
          ruleFormAdd.shopId = this.OrderInfo.shopId;
          // ruleFormAdd.toId = this.OrderInfo.toId; //
          ruleFormAdd.customerId = this.OrderInfo.customerId;
          ruleFormAdd.companyId = this.OrderInfo.companyId;
          ruleFormAdd.id = this.OrderInfo.id;
          let res = await saleAndRecharge(ruleFormAdd);
          // this.$message.success("续签订单成功");
          if (res.code == 200) {
            this.$emit("RenewalParams", res.data);
            this.$emit("handleClose");
            this.dialogVisible = false;
          }
        } else {
          return false;
        }
      });
    },
    async orderNext() {
      // 进入下一阶段
      let params = {
        step: this.OrderInfo.orderStep,
        oid: this.tableRow.id,
      };
      await orderNext(params);
      this.$emit("getOrderDetail", this.tableRow);
      this.$message.success("提交成功");
    },
    async addFeedback() {
      // 提交反馈
      let params = {
        orderId: this.tableRow.id,
        content: this.feedbackContent,
        pictureId: this.pictureId,
        picture: this.pictureName,
        fileId: this.fileId,
        cmsFile: this.fileName,
      };
      await orderFeedback(params);
      this.$message.success("提交成功");
      this.feedbackContent = "";
      this.$emit("getOrderDetail", this.tableRow);
    },
    getNowStage(row) {
      // 父组件传默认值
      let orderName = row.orderName || "";
      let newArr = [];
      if (orderName.indexOf("-") > -1) {
        newArr = orderName.split("-");
        if (!isNaN(Number(newArr[newArr.length - 1]))) {
          newArr = [...newArr, Number(newArr[newArr.length - 1]) + 1];
        } else {
          newArr = [...newArr, "1"];
        }
      } else {
        newArr = [orderName, "1"];
      }
      this.nowStage = row.order_step;
      this.ruleFormAdd = {
        // customerId: row.customer_id,
        name: newArr.join("-"),
        custName: row.customerName || row.customer_name,
        chanceId: row.id,
      };
      this.pickerOptions = {
        // 指派分公司的禁用时间范围
        disabledDate: (time) => {
          return time.getTime() < new Date(row.endDate).getTime();
        },
      };
    },
    async addChanceFollow(state) {
      // 跟进发布按钮
      let params = {};
      switch (state) {
        case 0:
          params = {
            state: 0,
            type: this.recordRadio,
            content: this.recordContent,
            chanceId: this.tableRow.id,
          };
          break;
        case 1:
          params = { ...this.nextPlan, chanceId: this.tableRow.id };
          params.followTx = params.followTx ? 1 : 0;
      }
      await addChanceFollow(params);
      this.$emit("getOrderDetail", this.tableRow);
      this.recordContent = "";
      this.recordRadio = 1;
      this.checkPlan = false;
      (this.nextPlan = {
        // 跟进计划
        state: 1,
        type: 1,
        remind: null,
        planTime: "",
        followTx: false,
      }), // 下次跟进计划提交数据
        this.$message.success("添加成功");
    },
    // editBtn() {
    //   this.$emit("openEdit");
    // },
    editBtn(name, value) {
      this.$refs["editRuleForm"].validate(async (valid) => {
        if (valid) {
          // 发送请求
          this.editParams = {
            filedName: name,
            filedValue: value,
          };
          this.editRuleForm[name] = value;
        } else {
          return;
        }
      });
    },
    async orderStateFun(value, id) {
      let params = {
        orderStageState: value ? "1" : "0",
      };
      await updateOrder(params, id);
      this.$emit("getOrderDetail", this.tableRow);
      this.$message.success("更新成功");
    },
    async deleteSea() {
      // 删除客户
      await deleteSea({}, this.tableRow.id);
      this.$message.success("删除成功");
      this.handleClose();
    },
    handleClose(done) {
      this.$emit("handleClose", done);
    },
    handleClick() {
      console.log(this.activeName);
    },
  },
};
</script>
<style lang="less" scoped>
.orderDetail-drawer {
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
    }
    .fileImg {
      width: 80px;
      margin-top: 8px;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .upload-container {
        margin-top: 24px;
        .fileList-container {
          border: 1px solid #EBEEF5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #EBEEF5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid rgba(220, 223, 230, 100);
            color: #666;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
            }
          }
        }
      }
      .cancel {
        cursor: pointer;
      }
      /deep/ .el-input__inner,
      .el-input-group__append {
        height: 32px;
        line-height: 32px;
      }
      /deep/ .el-input-group__append {
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .rightTop {
        border-bottom: 1px #eaeaeb solid;
        padding: 24px;
        font-size: 14px;
        color: #101010;
        .visitIcon {
          width: 14px;
          vertical-align: middle;
          margin-right: 8px;
        }
        .notContent {
          margin-top: 24px;
        }
      }
      /deep/ .el-tabs__content {
        padding-left: 16px;
      }
      .record-item {
        margin-top: 16px;
        border-top: 1px #eaeaeb dashed;
        padding-top: 16px;
        .item-content {
          margin-left: 22px;
          .itemBtn {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 14px;
            border: 1px solid #1890ff;
            border-radius: 4px;
            font-size: 14px;
            color: #101010;
            margin: 16px 16px 8px 0;
          }
          .item-detail {
            color: #999;
            margin-top: 8px;
            vertical-align: middle;
            .visitIcon {
              width: 14px;
              margin-left: 8px;
            }
          }
        }
        .item-top {
          padding-right: 16px;
          .item-icon {
            display: inline-block;
            width: 4px;
            height: 4px;
            background-color: #fff;
            border: 4px #1890ff solid;
            border-radius: 50%;
            margin-right: 8px;
          }
          .item-date {
            float: right;
            color: #999;
          }
        }
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tit-info {
        font-size: 18px;
      }
      .hoverEdit {
        display: inline-block;
        width: 330px;
        padding: 6px 12px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center
            #f2f9ff;
        }
      }
    }
    .detail-content {
      .edit-input-container {
        display: inline-block;
        width: 303px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .editInputContainer {
        width: 403px;
      }
      .edit-input {
        width: 200px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
      }
      .dateInput {
        width: 225px;
      }
      .hoverEdit {
        display: inline-block;
        width: 330px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center
            #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 330px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        // margin-right: 16px;
        border-radius: 4px;
      }
      .modifyPrice {
        display: inline-block;
        vertical-align: middle;
        padding: 0 86px 0 6px;
        height: 28px;
        line-height: 28px;
        border: #1890ff 1px solid;
        border-radius: 4px;
        margin-left: 8px;
        position: relative;
        &::after {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 6px;
          content: '手动调价';
          color: #1890ff;
          cursor: pointer;
        }
      }
    }
    .about-content {
      padding-top: 16px;
    }
    .data-content {
      .detail-content {
        div,
        span {
          font-size: 12px;
        }
        div {
          width: 45%;
          height: 32px;
          line-height: 32px;
        }
      }
      .data-title {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        color: #000000a6;
        border-bottom: 1px dashed #ccc;
        margin-top: 16px;
      }
    }
  }

  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
}
/deep/.el-step__title {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-left: -25px;
}
/deep/.el-step__icon-inner {
  font-size: 14px !important;
  width: 12px !important;
  margin-left: -2px;
}
/deep/.el-step__icon.is-icon {
  width: 10px !important;
}
.dialog-input {
  width: 320px !important;
}
// /deep/ .el-dialog__body {
//   overflow: hidden;
// }
.introducer {
  position: relative;
  padding: 24px 0;
  &::before {
    background-color: #f5f5f5;
    position: absolute;
    display: block;
    content: " ";
    height: 1px;
    width: 120%;
    left: -10%;
    top: 0;
  }
}
</style>
